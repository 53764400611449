<script setup>
    import PrimevueSteps from 'primevue/steps';

    const props = defineProps({
        model: {
            type: Array,
            required: true
        },

        previousClass: {
            type: String,
            default: null
        }
    });

    const activeIndex = props.model.map(item => item.to).indexOf(useRoute().path);
</script>

<template>
    <PrimevueSteps
        :activeStep="activeIndex"
        :model="props.model"
        :class="props.previousClass"
    />
</template>

<style lang="scss">
    .p-steps {
        width: 100%;

        .p-steps-item .p-menuitem-link {
            background: transparent !important;
        }

        &.p-steps-success .p-steps-item:not(.p-steps-current ~ .p-steps-item):not(.p-steps-current) {

            & > .p-menuitem-link > span {
                color: green;

                &.p-steps-number {
                    background-color: lightgreen;
                }

                &.p-steps-title {
                    font-weight: bold;
                }
            }
        }
    }
</style>
